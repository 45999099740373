<!-- eslint-disable vue/no-v-html -->
<template>
  <Container itemscope itemtype="https://schema.org/Question" size="medium">
    <div>
      <Headline :level="2" :size="4">
        <span itemprop="name">
          <span aria-hidden="true" class="question-char">Q:</span>
          {{ question }}
        </span>
      </Headline>
      <div
        itemscope
        itemtype="https://schema.org/Answer"
        itemprop="acceptedAnswer"
      >
        <!-- eslint-disable-next-line -->
        <RichText itemprop="text" v-html="answer" />
      </div>
    </div>
  </Container>
</template>

<script>
  export default {
    props: {
      question: {
        type: String,
        default: "",
      },
      answer: {
        type: String,
        default: "",
      },
    },
  }
</script>

<style lang="scss" scoped>

  .question-char {
    font-weight: bold;
  }
</style>
